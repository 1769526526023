<template>
  <div>
    <div class="contentsWidth mx-auto mt-4">
      <div class="">
        <p class="title mb-0">申請フォーム</p>
        <hr class="title">
      </div>
      <div id="serch-wrap" class="mt-4">
        <div class="bold">絞り込み条件</div>
        <div class="search-area flex flexCenter">

          <div class="long-input inlineBlock search-space">
            <div>年度</div>
            <div>
              <b-form-select
                :options="yearList"
                v-model="search.year"
                @input="fetchFormData">
                <template v-slot:first>
                  <option :value="null">-- 選択してください。 --</option>
                </template>
              </b-form-select>
            </div>
          </div>

          <div class="long-input inlineBlock search-space">
            <div>助成種別</div>
            <div>
                <!-- :options="apptypeListData[filter.year]" -->
              <b-form-select
                :options="seriesList"
                v-model="search.series"
                @input="fetchFormData">
                <template v-slot:first>
                  <option :value="null">-- 選択してください。 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="noInput"
        class="contentsWidth mx-auto">
        <p class="notice text-center">年度と助成種別を選択してください。</p>
      </div>

      <b-tabs
        class="gas-tab"
        nav-wrapper-class="gas-tab-wrap">
        <b-tab
          title-link-class="px-5">
          <v-date-form
            :errors.sync="errors"
            :now.sync="now"
            :noInput.sync="noInput"
            :series="search.series"/>
          <template v-slot:title>
            <span>期間設定</span>
            <img
              v-show="Object.keys(errors).length > 0"
              class="errorMark"
              src="@/assets/svg/error.svg">
          </template>
        </b-tab>
        <b-tab
          title-link-class="px-4">
          <template v-slot:title>
            <span>ファイル設定</span>
            <img
              v-show="Object.keys(fileErrors).length > 0"
              class="errorMark"
              src="@/assets/svg/error.svg"/>
          </template>
          <v-files-form
            :series="search.series"
            :fileErrors="fileErrors"
            :downloadFile="downloadFile"
            :noInput.sync="noInput"/>
        </b-tab>
      </b-tabs>

      <div class="mt-5 mb-3 flex flexCenter contentsWidth mx-auto">
        <b-button
          class="btn btn-lg bold mr-4"
          to="/cms/top">トップに戻る</b-button>
        <b-button
          @click="save"
          variant="primary"
          :disabled="noInput"
          class="btn btn-lg bold">保存</b-button>
      </div>

    </div>
  </div>
</template>

<script>
import moment from 'moment';
import api from '@/modules/api';
import download from '@/modules/download';
import DateForm from '@/components/cms/form/Date.vue';
import FilesForm from '@/components/cms/form/Files.vue';

export default {
  name: 'CmsFormEditView',
  components: {
    'v-date-form': DateForm,
    'v-files-form': FilesForm,
  },
  data() {
    return {
      search: {
        year: null,
        series: null,
      },
      now: moment(),
      dbYearList: [],
      dbSeriesList: [],
      errors: {},
      fileErrors: {},
    };
  },
  computed: {
    noInput() {
      return this.search.year === null || this.search.series === null;
    },
    apptypeId() {
      return this.$store.state.cmsForm.apptypeId;
    },
    apptype() {
      return this.$store.state.cmsForm.apptype;
    },
    stages() {
      return this.$store.state.cmsForm.stages;
    },
    report() {
      return this.$store.state.cmsForm.report;
    },
    yearList() {
      const yearList = this.dbYearList.slice();
      const nowYear = Number(moment().format('YYYY'));
      if (yearList.indexOf(nowYear) === -1) {
        yearList.unshift(nowYear);
      }
      return yearList;
    },
    seriesList() {
      return this.dbSeriesList.map((series) => {
        return { value: series.id, text: series.name };
      });
    },
    fileAppForm() {
      return this.$store.state.cmsForm.fileAppForm;
    },
    fileAcceptance() {
      return this.$store.state.cmsForm.fileAcceptance;
    },
    fileCostList() {
      return this.$store.state.cmsForm.fileCostList;
    },
    fileReport() {
      return this.$store.state.cmsForm.fileReport;
    },
    fileCostReport() {
      return this.$store.state.cmsForm.fileCostReport;
    },
    realFiles() {
      return this.$store.state.cmsForm.realFiles;
    },
    isUseGroup() {
      return this.$store.state.cmsForm.isUseGroup;
    },
  },
  methods: {
    init() {
      this.errors = {};
      this.fileErrors = {};
      this.now = moment();
    },
    async initFetch() {
      const response = await api.sendForm('/api/cms/form/init')
        .catch((error) => {
          console.log(error);
          return false;
        });
      if (response === false) {
        console.error('error');
      }
      this.dbYearList = response.data.yearList;
      this.dbSeriesList = response.data.seriesList;
    },
    async fetchFormData() {
      if (this.search.year === null || this.search.series === null) {
        this.init();
        this.$store.commit('cmsForm/init');
        return;
      }
      this.$store.dispatch('page/onWaiting');
      this.init();
      const response = await api.send('/api/cms/form/edit', this.search)
        .catch((error) => {
          console.log(error);
          return false;
        });
      this.$store.commit('cmsForm/init');
      if (response === false) {
        this.alert('データの取得に失敗しました。');
        return;
      }
      this.$store.commit('cmsForm/setDbData', response.data.formData);
      this.$store.commit('cmsForm/setDbFileData', response.data.fileData);
      this.$store.commit('cmsForm/setDbFileData4Report', response.data.fileReportData);
      const isUseGroup = response.data.isUseGroup === 1;
      this.$store.commit('cmsForm/updateGroupFlg', isUseGroup);
      this.$store.dispatch('page/offWaiting');
    },
    async save() {
      this.$store.dispatch('page/onWaiting');
      this.init();
      const base = Object.assign(this.search);
      base.apptypeId = this.apptypeId;
      const params = {
        base: JSON.stringify(base),
        apptype: JSON.stringify(this.apptype),
        stages: JSON.stringify(this.stages),
        report: JSON.stringify(this.report),
        fileAppForm: JSON.stringify(this.fileAppForm),
        fileAcceptance: JSON.stringify(this.fileAcceptance),
        fileCostList: JSON.stringify(this.fileCostList),
        fileReport: JSON.stringify(this.fileReport),
        fileCostReport: JSON.stringify(this.fileCostReport),
        isUseGroup: JSON.stringify(this.isUseGroup),
      };
      Object.keys(this.realFiles).forEach((key) => {
        params[`real_${key}`] = this.realFiles[key];
      });
      const response = await api.sendForm('/api/cms/form/save', params)
        .catch(async (error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.messages;
            this.fileErrors = error.response.data.fileMessages;
            this.alert('入力内容に誤りがありました。');
          } else {
            this.alert('保存に失敗しました。');
          }

          return false;
        });
      this.$store.dispatch('page/offWaiting');
      if (response === false) {
        return;
      }
      this.alert('保存しました。', false);
      // 絶対に再読み込みが必要。新規登録の場合、登録後に編集登録のトリガーになるapptypeIDを取得する必要があるので。
      await this.fetchFormData();
    },
    async downloadFile(assetId, fileName, isReport) {
      const param = {
        assetId,
        isReport,
      };
      const url = '/api/cms/form/download';

      this.$store.dispatch('page/onWaiting');
      const response = await api.send(url, param, true)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, fileName);
    },
  },
  async created() {
    this.$store.dispatch('page/onLoading');
    this.$store.commit('cmsForm/init');
    await this.initFetch();
    this.$store.dispatch('page/offLoading');
  },
};
</script>

<style scoped>
  #serch-wrap input, #serch-wrap select {
    height: 50px;
  }

  .year-select {
    height: 40px;
    width: 120px;
  }

  .search-area {
    background: #DDD;
    padding: 10px;
    margin-bottom: 30px;
  }

  .long-input {
    width: 520px;
  }

  .notice {
    color: #dc3545;
  }
  .errItem input{
    border:solid 1px #dc3545;
  }
  .error{
    font-size: 12.8px;
    color: #dc3545;
    display: block;
    width: 582px;
    margin-left: 15px;
    margin-top: 3px;
  }
  .errorMark {
    position: absolute;
    top: -7px;
    left: 150px;
  }
</style>
