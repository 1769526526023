var render = function render(){var _vm=this,_c=_vm._self._c;return _c('perfect-scrollbar',{staticClass:"p-4 ContentsBox"},[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"mt-4 label",attrs:{"sm":"3"}},[_c('div',{staticClass:"badgeWrap"},[_c('div',{class:[
          'mr-2 badge badge-required',
          _vm.noInput ? 'opacity03' : '',
          ]},[_vm._v("必須")])]),_c('label',{class:[
        'labelText bold require-label',
        _vm.noInput ? 'opacity03' : '',
        ]},[_vm._v("申請書")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('v-file-form',{attrs:{"fileKey":"fileAppForm","fileData":_vm.fileAppForm,"hasError":_vm.fileErrors.fileAppForm && _vm.fileErrors.fileAppForm.length>0,"downloadFile":_vm.downloadFile,"noInput":_vm.noInput},on:{"update:fileData":function($event){_vm.fileAppForm=$event},"update:file-data":function($event){_vm.fileAppForm=$event},"kickUpload":_vm.upload}})],1)],1),_vm._l((_vm.fileErrors.fileAppForm),function(msg,index){return _c('p',{key:`fileAppForm${index}`,staticClass:"mb-0 error"},[_vm._v(" "+_vm._s(msg)+" ")])}),_c('hr'),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"mt-4 label",attrs:{"sm":"3"}},[_c('div',{staticClass:"badgeWrap"},[_c('div',{class:[
          'mr-2 badge badge-required',
          _vm.noInput ? 'opacity03' : '',
          ]},[_vm._v("必須")])]),_c('label',{class:[
        'labelText bold require-label',
        _vm.noInput ? 'opacity03' : '',
        ]},[_vm._v("承諾書")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('v-file-form',{attrs:{"fileKey":"fileAcceptance","fileData":_vm.fileAcceptance,"hasError":_vm.fileErrors.fileAcceptance && _vm.fileErrors.fileAcceptance.length>0,"downloadFile":_vm.downloadFile,"noInput":_vm.noInput},on:{"update:fileData":function($event){_vm.fileAcceptance=$event},"update:file-data":function($event){_vm.fileAcceptance=$event},"kickUpload":_vm.upload}})],1)],1),_vm._l((_vm.fileErrors.fileAcceptance),function(msg,index){return _c('p',{key:`fileAcceptance_${index}`,staticClass:"mb-0 error"},[_vm._v(" "+_vm._s(msg)+" ")])}),_c('hr'),(_vm.series === 2)?_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"mt-4 label",attrs:{"sm":"3"}},[_c('div',{staticClass:"badgeWrap"},[_c('div',{class:[
          'mr-2 badge badge-required',
          _vm.noInput ? 'opacity03' : '',
          ]},[_vm._v("必須")])]),_c('label',{class:[
        'labelText bold require-label',
        _vm.noInput ? 'opacity03' : '',
        ]},[_vm._v("費目一覧表")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('v-file-form',{attrs:{"fileKey":"fileCostList","fileData":_vm.fileCostList,"hasError":_vm.fileErrors.fileCostList && _vm.fileErrors.fileCostList.length>0,"downloadFile":_vm.downloadFile,"noInput":_vm.noInput},on:{"update:fileData":function($event){_vm.fileCostList=$event},"update:file-data":function($event){_vm.fileCostList=$event},"kickUpload":_vm.upload}})],1)],1):_vm._e(),_vm._l((_vm.fileErrors.fileCostList),function(msg,index){return _c('p',{key:`fileCostList_${index}`,staticClass:"mb-0 error"},[_vm._v(" "+_vm._s(msg)+" ")])}),(_vm.series === 2)?_c('hr'):_vm._e(),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"mt-4 label",attrs:{"sm":"3"}},[_c('label',{class:[
        'labelText bold require-label',
        _vm.noInput ? 'opacity03' : '',
        ]},[_vm._v("成果報告書")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('v-file-form',{attrs:{"isReport":true,"fileKey":"fileReport","fileData":_vm.fileReport,"hasError":_vm.fileErrors.fileReport && _vm.fileErrors.fileReport.length>0,"downloadFile":_vm.downloadFile,"noInput":_vm.noInput},on:{"update:fileData":function($event){_vm.fileReport=$event},"update:file-data":function($event){_vm.fileReport=$event},"kickUpload":_vm.upload}})],1)],1),_vm._l((_vm.fileErrors.fileReport),function(msg,index){return _c('p',{key:`fileReport_${index}`,staticClass:"mb-0 error"},[_vm._v(" "+_vm._s(msg)+" ")])}),_c('hr'),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"mt-4 label",attrs:{"sm":"3"}},[_c('label',{class:[
        'labelText bold require-label',
        _vm.noInput ? 'opacity03' : '',
        ]},[_vm._v("支出報告書")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('v-file-form',{attrs:{"isReport":true,"fileKey":"fileCostReport","fileData":_vm.fileCostReport,"hasError":_vm.fileErrors.fileCostReport && _vm.fileErrors.fileCostReport.length>0,"downloadFile":_vm.downloadFile,"noInput":_vm.noInput},on:{"update:fileData":function($event){_vm.fileCostReport=$event},"update:file-data":function($event){_vm.fileCostReport=$event},"kickUpload":_vm.upload}})],1)],1),_vm._l((_vm.fileErrors.fileCostReport),function(msg,index){return _c('p',{key:`fileCostReport_${index}`,staticClass:"mb-0 error"},[_vm._v(" "+_vm._s(msg)+" ")])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }