<template>
  <div>
    <b-input-group>
      <span
        v-show="fileData.uploadedFileName"
        class="mb-2 fileName">
        ファイル名：{{fileData.uploadedFileName}}
      </span>
      <span
        v-show="!fileData.uploadedFileName && fileData.dbFileName"
        class="mb-2 fileName">
        ファイル名：
        <b-link
          @click="downloadFile(fileData.dbAssetId, fileData.dbFileName, isReport)"
          class="mb-1">{{fileData.dbFileName}}</b-link>
      </span>
      <span
        v-show="!fileData.uploadedFileName && !fileData.dbFileName"
        class="mb-2 fileName">
        ファイル名：ファイルが選択されていません。
      </span>
    </b-input-group>
    <b-input-group>
      <div
        @dragover.prevent="toggleDrag($event, true)"
        @dragleave.prevent="toggleDrag($event, false)"
        @drop.prevent="onDrop($event)"
        :class="[
          isDragging ? 'is-dragging' : '',
          hasError ? 'errItem' : '',
          'drop', 'flex', 'flexMiddle', 'flexCenter'
        ]">
        <span>
          <span
            :class="[
            isDragging ? 'is-dragging' : '',
            'dragText', 'fw-6'
            ]">ここにファイルをドラッグ＆ドロップ または</span>
          <label
            v-if="!noInput"
            :for="fileKey"
            class="fileText fw-6 selectFile">［ ファイルを選択 ］</label>
          <label
            v-if="noInput"
            class="fileText fw-6 selectFile">［ ファイルを選択 ］</label>
          <p
            :class="[
              isDragging ? 'is-dragging' : '',
              'fileNotation', 'mb-0'
            ]">
            （ファイルを再アップロードした場合アップロード済みのファイルは上書きされます）
            </p>
          <input type="file" style="display:none;"
            :id="fileKey"
            @change="upload($event)"
          >
        </span>
      </div>

    </b-input-group>
  </div>
</template>

<script>


export default {
  name: 'cmsFromFile',
  components: {
  },
  props: {
    errors: {},
    fileKey: {},
    fileData: { type: Object, default: () => {} },
    hasError: {},
    downloadFile: { type: Function },
    noInput: { type: Boolean, default: true },
    isReport: { type: Boolean, default: false },
  },
  data() {
    return {
      isDragging: false,
    };
  },
  methods: {
    toggleDrag(event, isDragging) {
      this.isDragging = isDragging;
    },
    onDrop(event) {
      this.isDragging = false;
      const fileList = event.target.files ? event.target.files : event.dataTransfer.files;
      // ファイルが無い時処理を中止
      if (fileList.length === 0) {
        return;
      }
      if (this.noInput) {
        return;
      }
      const file = fileList[0];
      const param = {
        uploadedFileName: file.name,
        uploadedFileKey: this.fileKey,
        realFile: file,
      };
      this.$emit('kickUpload', param);
    },
    upload(event) {
      if (event.target.files.length === 0) {
        return;
      }
      if (this.noInput) {
        return;
      }
      const file = event.target.files[0];
      const param = {
        uploadedFileName: file.name,
        uploadedFileKey: this.fileKey,
        realFile: file,
      };
      this.$emit('kickUpload', param);
    },
  },
  computed: {
  },
  // ロード画面
  created() {
  },
};
</script>

<style scoped>
  .opacity03 {
    opacity: 0.3;
  }

  .label {
    height: 50px;
  }

  .labelText {
    position: absolute;
    top: 10px;
  }

  .error {
    color: #dc3545;
    margin-left: 265px;
  }

  .addBtn {
    color:#0A8EA7 !important;
    border-color: #0A8EA7 !important;
    border-radius: 30px;
    width: 200px;
  }

  .btn-outline-primary:hover {
    background-color: #fff;
    opacity: 0.6;
  }

  .drop {
    height: 120px;
    width: 100%;
    background-color: #F3F3F3;
    border-color: #CCC;
    border-style: dashed;
    border-radius: 10px;
    border-width: 1px;
  }

  .dragText {
    color: #BBB;
  }

  .fileText {
    color: #0A8EA7;
  }

  .fileNotation {
    color: #BBB;
  }

  .is-dragging.drop {
    background-color: #E6E6E6;
    border-color: #6E6E6E;
  }

  .is-dragging {
    color: #6E6E6E;
  }

  .selectFile {
    cursor: pointer;
  }
  .selectFile:hover {
    text-decoration: underline;
  }

  .fileName {
    margin-top: 16px;
  }

  .errItem {
    border:solid 1px #dc3545 !important;
  }
</style>
