<template>
  <perfect-scrollbar class="p-4 ContentsBox">
    <b-row class="mt-2">
      <b-col sm="3" class="mt-4 label">
        <div class="badgeWrap">
          <div
            :class="[
            'mr-2 badge badge-required',
            noInput ? 'opacity03' : '',
            ]">必須</div>
        </div>
        <label :class="[
          'labelText bold require-label',
          noInput ? 'opacity03' : '',
          ]">申請公開日時</label>
      </b-col>
      <b-col sm="9" class="flex flexMiddle">
        <v-form-calendar-datetime
          class="mt-2 pb-2"
          :noLabel='true'
          :noMinute='true'
          name="barth"
          @change="updateApptypeAnnouncementDatetime"
          :value="apptype.application_announcement_datetime"
          itemStyle="line"
          inputClass="w-small h-50px"
          ymdWrapClass="w-100px"
          :hasErr="hasErr('apptypeAnnounce')"
          :disabled="noInput|| timeOver(apptype.application_announcement_datetime)"
          :canPast="false"/>
        <div
          v-if="!timeOver(apptype.application_announcement_datetime)"
          class="deleteBtn inlineBlock">
          <img
            @click="resetValue('apptype', 'application_announcement_datetime')"
            src="@/assets/svg/close.svg"
            class="tdDeleteBtn">
        </div>
      </b-col>
    </b-row>
    <p
      v-for="(msg, index) in errors.apptypeAnnounce" :key="`apptypeAnnounce_${index}`"
      class="mb-0 error">
      {{msg}}
    </p>
    <hr>

    <b-row class="mt-2">
      <b-col sm="3" class="mt-3 label">
        <div class="badgeWrap">
          <div
            :class="[
            'mr-2 badge badge-required',
            noInput ? 'opacity03' : '',
            ]">必須</div>
        </div>
        <label :class="[
          'labelText bold require-label',
          noInput ? 'opacity03' : '',
          ]">申請期間</label>
      </b-col>
      <b-col sm="9" class="flex flexMiddle">
        <v-form-calendar-datetime
          class="mt-2 pb-2"
          :noLabel='true'
          :noMinute='true'
          name="barth"
          @change="updateApptypeOpenDatetime"
          :value.sync="apptype.open_datetime"
          itemStyle="line"
          inputClass="w-small h-50px"
          ymdWrapClass="w-100px"
          :hasNull="true"
          :hasErr="hasErr('apptypeOpen')"
          :disabled="noInput || timeOver(apptype.open_datetime)"
          :canPast="false"/>
        <span class="mx-3">～</span>
        <v-form-calendar-datetime
          class="mt-2 pb-2"
          :noLabel='true'
          :noMinute='true'
          name="barth"
          @change="updateApptypeCloseDatetime"
          :value.sync="apptype.close_datetime"
          itemStyle="line"
          inputClass="w-small h-50px"
          ymdWrapClass="w-100px"
          :hasNull="true"
          :hasErr="hasErr('apptypeClose')"
          :disabled="noInput || timeOver(apptype.close_datetime)"
          :canPast="false"/>
        <div
          v-if="!timeOver(apptype.open_datetime) || !timeOver(apptype.close_datetime)"
          class="deleteBtn inlineBlock">
          <img
            @click="resetValue('apptype', 'open_datetime', 'close_datetime')"
            src="@/assets/svg/close.svg"
            class="tdDeleteBtn">
        </div>
      </b-col>
    </b-row>
    <p
      v-for="(msg, index) in errors.apptypeOpen" :key="`apptypeOpen_${index}`"
      class="mb-0 error">
      {{msg}}
    </p>
    <p
      v-for="(msg, index) in errors.apptypeClose" :key="`apptypeClose_${index}`"
      class="mb-0 error">
      {{msg}}
    </p>
    <hr>

    <b-row class="mt-2">
      <b-col sm="3" class="mt-3 label">
        <label :class="[
          'labelText bold require-label',
          noInput ? 'opacity03' : '',
          ]">選考評価</label>
      </b-col>
      <b-col sm="9">
        <b-form-checkbox
          v-if="series === 2"
          class="inlineBlock mr-5"
          :checked="isUseGroup"
          @input="updateGroupFlg"
          :disabled="noInput || isEveTimeOver"
          switches
        >
          グループ設定を利用する
        </b-form-checkbox>
        <div
          v-for="(stage, index) in stages" :key="`stages_${index}`">
          <span class="mr-2">{{index+1}}次</span>
          <v-form-calendar-datetime
            class="mt-2 pb-2 inlineBlock"
            :noLabel='true'
            :noMinute='true'
            name="barth"
            @change="updateStageFrom"
            :updateParam='{index}'
            :value.sync="stage.from"
            itemStyle="line"
            inputClass="w-small h-50px"
            ymdWrapClass="w-100px"
            :hasNull="true"
            :hasErr="hasErr(`stages_${index+1}_from`)"
            :disabled="noInput || timeOver(stage.from) || nextTimeOver(index)"
            :canPast="false"/>
          <span class="mx-3 inlineBlock">～</span>
          <v-form-calendar-datetime
            class="mt-2 pb-2 inlineBlock"
            :noLabel='true'
            :noMinute='true'
            name="barth"
            @change="updateStageTo"
            :updateParam='{index}'
            :value.sync="stage.to"
            itemStyle="line"
            inputClass="w-small h-50px"
            ymdWrapClass="w-100px"
            :hasNull="true"
            :hasErr="hasErr(`stages_${index+1}_to`)"
            :disabled="noInput || timeOver(stage.to) || nextTimeOver(index)"
            :canPast="false"/>
          <!-- 最後の選考段階かつ、fromもtoも過ぎていない場合だけ消せる -->
          <div
            v-if="stages.length === index + 1 && !timeOver(stage.from) && !timeOver(stage.to)"
            class="deleteBtn inlineBlock">
            <img
              @click="removeStage(index)"
              src="@/assets/svg/close.svg"
              class="tdDeleteBtn">
          </div>
        </div>
        <div
          v-if="!timeOver(apptype.result_announcement_datetime)"
          class="flex flexRight">
          <b-button
          class="addBtn"
          @click="addStage"
          variant="outline-primary">
            <img class="plus" src="@/assets/svg/plus.svg">
            入力欄を追加
          </b-button>
        </div>
      </b-col>
    </b-row>
    <div
      v-for="(stage, index) in stages" :key="`err_stage_${index}`">
      <p
        v-for="(msg, index) in errors[`stages_${index+1}_from`]" :key="`stage_from_${index}`"
        class="mb-0 error">
        {{msg}}
      </p>
      <p
        v-for="(msg, index) in errors[`stages_${index+1}_to`]" :key="`stage_to_${index}`"
        class="mb-0 error">
        {{msg}}
      </p>
    </div>
    <hr>

    <b-row class="mt-2">
      <b-col sm="3" class="mt-3 label">
        <label :class="[
          'labelText bold require-label',
          noInput ? 'opacity03' : '',
          ]">結果発表日時</label>
      </b-col>
      <b-col sm="9" class="flex flexMiddle">
        <v-form-calendar-datetime
          class="mt-2 pb-2"
          :noLabel='true'
          :noMinute='true'
          name="barth"
          @change="updateApptypeResultAnnouncementDatetime"
          :value.sync="apptype.result_announcement_datetime"
          itemStyle="line"
          inputClass="w-small h-50px"
          ymdWrapClass="w-100px"
          :hasNull="true"
          :hasErr="hasErr('apptypeResult')"
          :disabled="noInput || timeOver(apptype.result_announcement_datetime)"
          :canPast="false"/>
        <div
          v-if="!timeOver(apptype.result_announcement_datetime)"
          class="deleteBtn inlineBlock">
          <img
            @click="resetValue('apptype', 'result_announcement_datetime')"
            src="@/assets/svg/close.svg"
            class="tdDeleteBtn">
        </div>
      </b-col>
    </b-row>
    <p
      v-for="(msg, index) in errors.apptypeResult" :key="`apptypeResult_${index}`"
      class="mb-0 error">
      {{msg}}
    </p>
    <hr>

    <b-row class="mt-2">
      <b-col sm="3" class="mt-3 label">
                <label :class="[
          'labelText bold require-label',
          noInput ? 'opacity03' : '',
          ]">報告公開日時</label>
      </b-col>
      <b-col sm="9" class="flex flexMiddle">
        <v-form-calendar-datetime
          class="mt-2 pb-2"
          :noLabel='true'
          :noMinute='true'
          name="barth"
          @change="updateReportAnnouncementDatetime"
          :value.sync="report.report_announcement_datetime"
          itemStyle="line"
          inputClass="w-small h-50px"
          ymdWrapClass="w-100px"
          :hasNull="true"
          :hasErr="hasErr('reportAnnounce')"
          :disabled="noInput || timeOver(report.report_announcement_datetime)"
          :canPast="false"/>
        <div
          v-if="!timeOver(report.report_announcement_datetime)"
          class="deleteBtn inlineBlock">
          <img
            @click="resetValue('report', 'report_announcement_datetime')"
            src="@/assets/svg/close.svg"
            class="tdDeleteBtn">
        </div>
      </b-col>
    </b-row>
    <p
      v-for="(msg, index) in errors.reportAnnounce" :key="`reportAnnounce_${index}`"
      class="mb-0 error">
      {{msg}}
    </p>
    <hr>

    <b-row class="mt-2">
      <b-col sm="3" class="mt-3 label">
                <label :class="[
          'labelText bold require-label',
          noInput ? 'opacity03' : '',
          ]">報告期間</label>
      </b-col>
      <b-col sm="9" class="flex flexMiddle">
        <v-form-calendar-datetime
          class="mt-2 pb-2"
          :noLabel='true'
          :noMinute='true'
          name="barth"
          @change="updateReportOpenDatetime"
          :value.sync="report.open_datetime"
          itemStyle="line"
          inputClass="w-small h-50px"
          ymdWrapClass="w-100px"
          :hasNull="true"
          :hasErr="hasErr('reportOpen')"
          :disabled="noInput || timeOver(report.open_datetime)"
          :canPast="false"/>
        <span class="mx-3">～</span>
        <v-form-calendar-datetime
          class="mt-2 pb-2"
          :noLabel='true'
          :noMinute='true'
          name="barth"
          @change="updateReportCloseDatetime"
          :value.sync="report.close_datetime"
          itemStyle="line"
          inputClass="w-small h-50px"
          ymdWrapClass="w-100px"
          :hasNull="true"
          :hasErr="hasErr('reportClose')"
          :disabled="noInput || timeOver(report.close_datetime)"
          :canPast="false"
          />
        <div
          v-if="!timeOver(report.open_datetime) || !timeOver(report.close_datetime)"
          class="deleteBtn inlineBlock">
          <img
            @click="resetValue('report', 'open_datetime', 'close_datetime')"
            src="@/assets/svg/close.svg"
            class="tdDeleteBtn">
        </div>
      </b-col>
    </b-row>
    <p
      v-for="(msg, index) in errors.reportOpen" :key="`reportOpen_${index}`"
      class="mb-0 error">
      {{msg}}
    </p>
    <p
      v-for="(msg, index) in errors.reportClose" :key="`reportClose_${index}`"
      class="mb-0 error">
      {{msg}}
    </p>
  </perfect-scrollbar>
</template>

<script>
import moment from 'moment';

export default {
  name: 'cmsFromDate',
  components: {
  },
  props: {
    errors: {},
    now: {},
    noInput: { type: Boolean, default: true },
    series: null,
  },
  data() {
    return {
    };
  },
  methods: {
    resetValue(type, key, key2) {
      if (!this.timeOver(this[type][key])) {
        const param = {
          type,
          key,
          value: null,
        };
        this.$store.commit('cmsForm/setValue', param);
      }

      if (key2) {
        if (!this.timeOver(this[type][key2])) {
          const param2 = {
            type,
            key: key2,
            value: null,
          };
          this.$store.commit('cmsForm/setValue', param2);
        }
      }
    },
    updateApptypeAnnouncementDatetime(param) {
      this.$store.commit('cmsForm/updateApptypeAnnouncementDatetime', param.value);
    },
    updateApptypeOpenDatetime(param) {
      this.$store.commit('cmsForm/updateApptypeOpenDatetime', param.value);
    },
    updateApptypeCloseDatetime(param) {
      this.$store.commit('cmsForm/updateApptypeCloseDatetime', param.value);
    },
    updateApptypeResultAnnouncementDatetime(param) {
      this.$store.commit('cmsForm/updateApptypeResultAnnouncementDatetime', param.value);
    },
    updateReportAnnouncementDatetime(param) {
      this.$store.commit('cmsForm/updateReportAnnouncementDatetime', param.value);
    },
    updateReportOpenDatetime(param) {
      this.$store.commit('cmsForm/updateReportOpenDatetime', param.value);
    },
    updateReportCloseDatetime(param) {
      this.$store.commit('cmsForm/updateReportCloseDatetime', param.value);
    },
    updateStageFrom(param) {
      this.$store.commit('cmsForm/updateStageFrom', param);
    },
    updateStageTo(param) {
      this.$store.commit('cmsForm/updateStageTo', param);
    },
    updateGroupFlg(flg) {
      this.$store.commit('cmsForm/updateGroupFlg', flg);
    },
    addStage() {
      if (this.stages.length >= 10 || this.noInput) {
        return;
      }
      this.$store.commit('cmsForm/addStage');
    },
    // 最後の要素だけ消せる
    removeStage(index) {
      const count = this.stages.length;
      if (this.noInput || count !== index + 1) {
        return;
      }
      this.$store.commit('cmsForm/removeStage', index);
    },
  },
  computed: {
    apptype() {
      return this.$store.state.cmsForm.apptype;
    },
    stages() {
      return this.$store.state.cmsForm.stages;
    },
    report() {
      return this.$store.state.cmsForm.report;
    },
    hasErr() {
      return (key) => {
        if (!this.errors[key]) {
          return false;
        }
        return true;
      };
    },
    timeOver() {
      return (time) => {
        if (!time) {
          return false;
        }
        return moment(time).isSameOrBefore(this.now);
      };
    },
    nextTimeOver() {
      // このステージより後にあるステージが1つでも現在の時間を過ぎていたら、手前のものもdisbled
      return (index) => {
        let roopIndex = index + 1;
        const maxIndex = this.stages.length;
        let result = false;
        for (roopIndex; roopIndex < maxIndex; roopIndex += 1) {
          if (moment(this.stages[roopIndex].from).isSameOrBefore(this.now)) {
            result = true;
          }
        }
        return result;
      };
    },
    isUseGroup() {
      return this.$store.state.cmsForm.isUseGroup;
    },
    isEveTimeOver() {
      if (this.stages.length <= 1) {
        return false;
      }
      return this.timeOver(this.stages[1].to);
    },
  },
  // ロード画面
  created() {
  },
};
</script>

<style scoped>
  .opacity03 {
    opacity: 0.3;
  }

  .label {
    height: 50px;
  }

  .labelText {
    position: absolute;
    top: 10px;
  }

  .badgeWrap {
    margin-bottom: 10px;
  }

  .require-label {
    margin-left: 40px;
  }

  .error {
    color: #dc3545;
    margin-left: 265px;
  }

  .addBtn {
    color:#0A8EA7 !important;
    border-color: #0A8EA7 !important;
    border-radius: 30px;
    width: 200px;
  }

  .btn-outline-primary:hover {
    background-color: #fff;
    opacity: 0.6;
  }
</style>
