<template>
  <perfect-scrollbar class="p-4 ContentsBox">
    <b-row class="mt-2">
      <b-col sm="3" class="mt-4 label">
        <div class="badgeWrap">
          <div
            :class="[
            'mr-2 badge badge-required',
            noInput ? 'opacity03' : '',
            ]">必須</div>
        </div>
        <label :class="[
          'labelText bold require-label',
          noInput ? 'opacity03' : '',
          ]">申請書</label>
      </b-col>
      <b-col sm="9">
        <v-file-form
          fileKey="fileAppForm"
          :fileData.sync="fileAppForm"
          :hasError="fileErrors.fileAppForm && fileErrors.fileAppForm.length>0"
          @kickUpload="upload"
          :downloadFile="downloadFile"
          :noInput='noInput'/>
      </b-col>
    </b-row>
    <p
      v-for="(msg, index) in fileErrors.fileAppForm" :key="`fileAppForm${index}`"
      class="mb-0 error">
      {{msg}}
    </p>
    <hr>

    <b-row class="mt-2">
      <b-col sm="3" class="mt-4 label">
        <div class="badgeWrap">
          <div
            :class="[
            'mr-2 badge badge-required',
            noInput ? 'opacity03' : '',
            ]">必須</div>
        </div>
        <label :class="[
          'labelText bold require-label',
          noInput ? 'opacity03' : '',
          ]">承諾書</label>
      </b-col>
      <b-col sm="9">
        <v-file-form
          fileKey="fileAcceptance"
          :fileData.sync="fileAcceptance"
          :hasError="fileErrors.fileAcceptance && fileErrors.fileAcceptance.length>0"
          @kickUpload="upload"
          :downloadFile="downloadFile"
          :noInput='noInput'/>
      </b-col>
    </b-row>
    <p
      v-for="(msg, index) in fileErrors.fileAcceptance" :key="`fileAcceptance_${index}`"
      class="mb-0 error">
      {{msg}}
    </p>
    <hr>

    <b-row class="mt-2"
      v-if="series === 2">
      <b-col sm="3" class="mt-4 label">
        <div class="badgeWrap">
          <div
            :class="[
            'mr-2 badge badge-required',
            noInput ? 'opacity03' : '',
            ]">必須</div>
        </div>
        <label :class="[
          'labelText bold require-label',
          noInput ? 'opacity03' : '',
          ]">費目一覧表</label>
      </b-col>
      <b-col sm="9">
        <v-file-form
          fileKey="fileCostList"
          :fileData.sync="fileCostList"
          :hasError="fileErrors.fileCostList && fileErrors.fileCostList.length>0"
          @kickUpload="upload"
          :downloadFile="downloadFile"
          :noInput='noInput'/>
      </b-col>
    </b-row>
    <p
      v-for="(msg, index) in fileErrors.fileCostList" :key="`fileCostList_${index}`"
      class="mb-0 error">
      {{msg}}
    </p>
    <hr v-if="series === 2">

    <b-row class="mt-2">
      <b-col sm="3" class="mt-4 label">
        <label :class="[
          'labelText bold require-label',
          noInput ? 'opacity03' : '',
          ]">成果報告書</label>
      </b-col>
      <b-col sm="9">
        <v-file-form
          :isReport="true"
          fileKey="fileReport"
          :fileData.sync="fileReport"
          :hasError="fileErrors.fileReport && fileErrors.fileReport.length>0"
          @kickUpload="upload"
          :downloadFile="downloadFile"
          :noInput='noInput'/>
      </b-col>
    </b-row>
    <p
      v-for="(msg, index) in fileErrors.fileReport" :key="`fileReport_${index}`"
      class="mb-0 error">
      {{msg}}
    </p>
    <hr>

    <b-row class="mt-2">
      <b-col sm="3" class="mt-4 label">
        <label :class="[
          'labelText bold require-label',
          noInput ? 'opacity03' : '',
          ]">支出報告書</label>
      </b-col>
      <b-col sm="9">
        <v-file-form
          :isReport="true"
          fileKey="fileCostReport"
          :fileData.sync="fileCostReport"
          :hasError="fileErrors.fileCostReport && fileErrors.fileCostReport.length>0"
          @kickUpload="upload"
          :downloadFile="downloadFile"
          :noInput='noInput'/>
      </b-col>
    </b-row>
    <p
      v-for="(msg, index) in fileErrors.fileCostReport" :key="`fileCostReport_${index}`"
      class="mb-0 error">
      {{msg}}
    </p>

  </perfect-scrollbar>
</template>

<script>
import FileForm from '@/components/cms/form/parts/File.vue';

export default {
  name: 'cmsFromFiles',
  components: {
    'v-file-form': FileForm,
  },
  props: {
    fileErrors: { type: [Object, Array], default: () => {} },
    downloadFile: { type: Function },
    noInput: { type: Boolean, default: true },
    series: {},
  },
  data() {
    return {
    };
  },
  methods: {
    upload(param) {
      this.$store.commit('cmsForm/uploadFile', param);
    },
  },
  computed: {
    hasErr() {
      return (key) => {
        if (!this.errors[key]) {
          return false;
        }
        return true;
      };
    },
    fileAppForm() {
      return this.$store.state.cmsForm.fileAppForm;
    },
    fileAcceptance() {
      return this.$store.state.cmsForm.fileAcceptance;
    },
    fileCostList() {
      return this.$store.state.cmsForm.fileCostList;
    },
    fileReport() {
      return this.$store.state.cmsForm.fileReport;
    },
    fileCostReport() {
      return this.$store.state.cmsForm.fileCostReport;
    },
    realFiles() {
      return this.$store.state.cmsForm.realFiles;
    },
  },
  // ロード画面
  created() {
  },
};
</script>

<style scoped>

  .label {
    height: 50px;
  }

  .labelText {
    position: absolute;
    top: 10px;
  }

  .error {
    color: #dc3545;
    margin-left: 265px;
  }

  .errItem {
    border:solid 1px #dc3545 !important;
  }

  .require-label {
    margin-left: 40px;
  }

  .badgeWrap {
    margin-bottom: 10px;
  }
</style>
