var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-input-group',[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.fileData.uploadedFileName),expression:"fileData.uploadedFileName"}],staticClass:"mb-2 fileName"},[_vm._v(" ファイル名："+_vm._s(_vm.fileData.uploadedFileName)+" ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.fileData.uploadedFileName && _vm.fileData.dbFileName),expression:"!fileData.uploadedFileName && fileData.dbFileName"}],staticClass:"mb-2 fileName"},[_vm._v(" ファイル名： "),_c('b-link',{staticClass:"mb-1",on:{"click":function($event){return _vm.downloadFile(_vm.fileData.dbAssetId, _vm.fileData.dbFileName, _vm.isReport)}}},[_vm._v(_vm._s(_vm.fileData.dbFileName))])],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.fileData.uploadedFileName && !_vm.fileData.dbFileName),expression:"!fileData.uploadedFileName && !fileData.dbFileName"}],staticClass:"mb-2 fileName"},[_vm._v(" ファイル名：ファイルが選択されていません。 ")])]),_c('b-input-group',[_c('div',{class:[
        _vm.isDragging ? 'is-dragging' : '',
        _vm.hasError ? 'errItem' : '',
        'drop', 'flex', 'flexMiddle', 'flexCenter'
      ],on:{"dragover":function($event){$event.preventDefault();return _vm.toggleDrag($event, true)},"dragleave":function($event){$event.preventDefault();return _vm.toggleDrag($event, false)},"drop":function($event){$event.preventDefault();return _vm.onDrop($event)}}},[_c('span',[_c('span',{class:[
          _vm.isDragging ? 'is-dragging' : '',
          'dragText', 'fw-6'
          ]},[_vm._v("ここにファイルをドラッグ＆ドロップ または")]),(!_vm.noInput)?_c('label',{staticClass:"fileText fw-6 selectFile",attrs:{"for":_vm.fileKey}},[_vm._v("［ ファイルを選択 ］")]):_vm._e(),(_vm.noInput)?_c('label',{staticClass:"fileText fw-6 selectFile"},[_vm._v("［ ファイルを選択 ］")]):_vm._e(),_c('p',{class:[
            _vm.isDragging ? 'is-dragging' : '',
            'fileNotation', 'mb-0'
          ]},[_vm._v(" （ファイルを再アップロードした場合アップロード済みのファイルは上書きされます） ")]),_c('input',{staticStyle:{"display":"none"},attrs:{"type":"file","id":_vm.fileKey},on:{"change":function($event){return _vm.upload($event)}}})])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }